import React from "react";
import {
  FontWeights,
  getTheme,
  IconButton,
  mergeStyleSets,
  MessageBar,
  MessageBarType,
  Modal,
  ScrollablePane,
  SharedColors,
  Spinner,
  Stack,
  Text,
} from "@fluentui/react";
import { useBoolean } from "@uifabric/react-hooks";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ConfirmationForm } from "../../../components/shared/forms/ConfirmationForm";
import { ServiceForm } from "../../../components/shared/forms/ServiceForm";
import {
  IBarMessage,
  ImportFromFile,
} from "../../../components/shared/importFromFile/ImportFromFile";
import { CustomPanel } from "../../../components/shared/panel/CustomPanel";
import { SomethingWentWrong } from "../../../components/shared/somethingWentWrong/SomethingWentWrong";
import { portalApi } from "../../../core/api/api";
import { IService, IStore, IWorkflow } from "../../../core/store";
import { IRequestResult } from "../../../core/store/typings/IResponseResult";
import { strings } from "../../../localization/strings";
import { ServiceConfigurationDetails } from "./ServiceConfigurationDetails";
import { ServiceConfigurationHeader } from "./ServiceConfigurationHeader";
import { getLocalizedChannel, getLocalizedDeviceCheckSolution } from "../../../localization/helpers/channel";
import { endpoints } from "../../../core/api/endpoints";
import { ShareWorkflowDialog } from "./ServiceConfigurationDetails/ShareWorkflowDialog";
import {
  CheckAssesmentModuleAccess,
  CheckIdentificationModuleAccess,
  CheckProcessStageAccess,
  CheckProcessStageSectionOptionAccess,
  CheckWorkflowSettingsAccess,
  Experience,
} from "../../../components/shared/configurator/helpers/evisibility";
import {
  CatalogTypes,
  CommissionType,
  DiagnosticsModes,
  IdentificationMethods,
  IDiagnosticsConfig,
  Inspections,
  OfferProviderTypes,
  ProcessStages,
  ScannerIdentificationTypes,
  ScannerUploadMethods, ServiceTypes,
} from "@piceasoft/core";
import { DeviceCheckSolutions } from "../../../core/store/typings/DeviceCheckSolutions";
import {compareCurrentUTCDateTime} from "../../../core/helpers/date-time";
import {isEqual} from "lodash";

const isCheckedRequiredDataExists = (workflow: IWorkflow): boolean => {
  const {serviceType, selfService} = workflow
  if (serviceType === ServiceTypes.SelfService && !selfService) {
    return false
  }
  return true
}
const isSpecificDataMisplaced = (workflow: IWorkflow): boolean => {
  const {serviceType, selfService} = workflow
  if (serviceType !== ServiceTypes.SelfService && selfService) {
    return true
  }
  return false
}
type TProps = {};
const JSON_TYPE = "application/json";
export const ServiceConfiguration: React.FC<TProps> = () => {
  const [serviceState, setServiceState] = React.useState<{
    qrCodeLink: string;
    fetched: boolean;
    data?: IService;
  }>({ fetched: false, qrCodeLink: "" });
  const workflowIconGeneration = useSelector(
    (s: IStore) => s.workplace.organization?.workflowIconGeneration
  );
  const [error, setError] = React.useState<string>();
  const [
    confirmation,
    { setTrue: showConfirmation, setFalse: hideConfirmation },
  ] = useBoolean(false);
  const [isPanelOpen, { setTrue: showPanel, setFalse: hidePanel }] =
    useBoolean(false);
  const [formError, setFormError] = React.useState<string>();
  const { serviceId } = useParams<{ serviceId: string }>();
  const [fetching, { setTrue: startFetch, setFalse: stopFetch }] =
    useBoolean(false);
  const [
    isImportModalShowed,
    { setTrue: showImportModal, setFalse: hideImportModal },
  ] = useBoolean(false);
  const [infoFetching, { setTrue: startFetchInfo, setFalse: stopFetchInfo }] =
    useBoolean(false);
  const [infoWasSaved, setInfoWasSaved] = React.useState(false);
  const [workflowFileMessages, setWorflowFileMessages] =
    React.useState<IBarMessage[]>();
  const [
    workflowIsChecking,
    { setTrue: startWorkflowCheck, setFalse: stopWorkflowCheck },
  ] = useBoolean(false);
  const [
    isShareWorkflowPanelOpen,
    { setTrue: showShareWorkflow, setFalse: hideShareWorkflow },
  ] = useBoolean(false);
  const applicationUrl = useSelector(
    (s: IStore) => s.environment.applicationUrl
  );
  const [confirmIsDisabled, setConfirmDisabled] = React.useState(false);

  const history = useHistory();
  console.log("SERVICE ID", serviceId);
  React.useEffect(() => {
    getService(parseInt(serviceId));
  }, []);

  const checkWorkflowForUpload = async (file: File) => {
    startWorkflowCheck();
    let messages: IBarMessage[] = [];
    /**
     * Function to push a message to a messages array.
     * @param {string} text - The text of the message to push.
     * @param {MessageBarType} [type=MessageBarType.error] - The type of the message to push. Defaults to `MessageBarType.error`.
     */
    const pushMessage = (
      text: string,
      type: MessageBarType = MessageBarType.error
    ) => {
      messages.push({ text, type });
    };

    console.log("file:", file);
    if (file.type === JSON_TYPE) {
      try {
        const fileUrl = URL.createObjectURL(file);
        const workflowLoaded = (await (
          await fetch(fileUrl)
        ).json()) as IWorkflow;
        URL.revokeObjectURL(fileUrl);
        console.log("workflow:", workflowLoaded);
        setConfirmDisabled(false);
        const channelLoaded: Experience | undefined = workflowLoaded.channel;
        const serviceStateChannel = serviceState.data?.channel;

        let hasCatalogWarning = false;
        let hasPromotionWarning = false;

        let catalogsIds: string[] = [];
        const catalogsResult =
          await portalApi.organization.catalogs.getCatalogsByCatalogType(CatalogTypes.Tradein);
        if (catalogsResult.successed && catalogsResult.data) {
          catalogsIds = catalogsResult.data
            .filter((i) => !i.removed)
            .map((i) => i.id);
          console.log("catalogsResult.dataIds:", catalogsIds);
        }
        if (!workflowLoaded.channel) {
          pushMessage(strings.CONSTRUCTOR.WARNINGS.NO_SERVICE_CHANNEL);
        }
        if (
          serviceStateChannel &&
          channelLoaded &&
          serviceStateChannel != channelLoaded
        ) {
          pushMessage(
            strings.CONSTRUCTOR.WARNINGS.WRONG_SERVICE_CHANNEL +
              " " +
              getLocalizedChannel(serviceState.data?.channel)
          );
          setConfirmDisabled(true);
        }
        else if (serviceState.data?.deviceCheckSolution !== workflowLoaded.deviceCheckSolution) {
          // Same service channel, but different Device Check Solution.
          // Device check solution must match
          pushMessage(strings.CONSTRUCTOR.WARNINGS.WRONG_DEVICE_CHECK_SOLUTION + " " + getLocalizedDeviceCheckSolution(serviceState.data?.deviceCheckSolution));
          setConfirmDisabled(true);
        }
        if(workflowLoaded?.channel !== undefined && workflowLoaded?.channel === Experience.Retail){
          if(workflowLoaded.result?.userSatisfactionSurvey !== undefined){
              let uss = workflowLoaded.result?.userSatisfactionSurvey;
              if(uss.csatSurvey === true || uss.npsSurvey === true || uss.customSurvey === true
               || uss.customCsatRating !== undefined || uss.customCsatRatingScale !== undefined)
              {
              messages.push({
                  text:strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.USER_SATISFACTION_SURVEY.USER_SATISFACTION_SURVEY_NOT_ALLOWED_ERROR,
                  type: MessageBarType.error })
              setConfirmDisabled(true);
             }
          }
        }

        // validate assesment modules
        if (!isAssesmentModulesValid(serviceState.data, workflowLoaded)) {
          pushMessage(`${strings.CONSTRUCTOR.WARNINGS.CONFIGURATION_INCORRECT} ${strings.CONSTRUCTOR.WARNINGS.UNSUPPORTED_MODULE}`);
          setConfirmDisabled(true);
        }

        if (!isCommonOfferValid(workflowLoaded)) {
          console.log("commonOffer is not valid!");
          pushMessage(`${strings.CONSTRUCTOR.WARNINGS.CONFIGURATION_INCORRECT} ${strings.CONSTRUCTOR.WARNINGS.UNSUPPORTED_MODULE}`);
          setConfirmDisabled(true);
        }

        if(workflowLoaded.result?.userSatisfactionSurvey !== undefined){
          let uss = workflowLoaded.result?.userSatisfactionSurvey;
          if(uss?.csatSurvey === true && uss?.npsSurvey === true )
          {
              messages.push({
                  text:strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.USER_SATISFACTION_SURVEY.USER_SATISFACTION_SURVEY_ONLY_ONE_ALLOWED_ERROR,
                  type: MessageBarType.error })
              setConfirmDisabled(true);
          }
        }
        if (channelLoaded) {
          const identificationModuleFails =
            workflowLoaded.identification.items.map(
              (i) => !CheckIdentificationModuleAccess(channelLoaded, i.method)
            );

          const assessmentModuleFails = Object.keys(
            workflowLoaded.assessment?.inspections || {}
          ).map((key) => {
            return workflowLoaded.assessment?.inspections[key]
              ? !CheckAssesmentModuleAccess(
                  channelLoaded,
                  key as Inspections,
                  serviceState.data?.deviceCheckSolution.toString() || ""
                )
              : false;
          });
          const processStageAccessFails = workflowLoaded.stages?.map(
            (s) => !CheckProcessStageAccess(channelLoaded, s.type)
          );
          const checkSettingFail = (
            check: boolean | undefined,
            settingName: string
          ): boolean =>
            !!check && !CheckWorkflowSettingsAccess(channelLoaded, settingName);

          const workflowSettingsFails =
            checkSettingFail(
              workflowLoaded.isControlTransaction,
              "This is control workflow"
            ) ||
            checkSettingFail(
              workflowLoaded.useGradesCategories,
              "Use grade categories"
            ) ||
            checkSettingFail(
              !!workflowLoaded.deviceTypes,
              "Filter devices by category"
            );
          const checkStageSection = (
            type: ProcessStages,
            section: string,
            option: string
          ) =>
            !CheckProcessStageSectionOptionAccess(
              channelLoaded,
              type,
              section,
              option
            );

          const processStageSectionOptionAccessFails =
            workflowLoaded.stages?.map((s) => {
              const stageSectionCases: [unknown, string, string][] = [
                [
                  workflowLoaded.assessment?.requirements,
                  "Device Identifiers",
                  "Require device identifiers",
                ],
                [
                  workflowLoaded.assessment?.ui?.requirements?.title,
                  "Device Identifiers",
                  "Use a custom title",
                ],
                [
                  workflowLoaded.assessment?.ui?.requirements?.information,
                  "Device Identifiers",
                  "Use custom information",
                ],
                [
                  workflowLoaded.assessment?.requirements?.imei,
                  "Device Identifiers",
                  "Requires IMEI",
                ],
                [
                  workflowLoaded.assessment?.requirements?.imei2,
                  "Device Identifiers",
                  "Requires IMEI2",
                ],
                [
                  workflowLoaded.assessment?.requirements?.sn,
                  "Device Identifiers",
                  "Requires serial number",
                ],
                [
                  workflowLoaded.assessment?.hideDependency,
                  "Additional",
                  "Hide dependent module",
                ],
                [
                  workflowLoaded.assessment?.hideSkiped,
                  "Additional",
                  "Hide skipped modules",
                ],
                [
                  workflowLoaded.assessment?.collapseDone,
                  "Additional",
                  "Collapse completed modules",
                ],
                [
                  workflowLoaded.result?.contractButton,
                  "Buttons",
                  "Show contract button",
                ],
                [
                  workflowLoaded.result?.contractButton?.icon,
                  "Buttons",
                  "Custom Print button icon",
                ],
                [
                  workflowLoaded.result?.contractButton?.text,
                  "Buttons",
                  "Custom Print button caption",
                ],
                [
                  workflowLoaded.result?.contractButton?.tooltip,
                  "Buttons",
                  "Custom Print button tooltip text",
                ],
                [
                  workflowLoaded.assessment?.cancelOptions,
                  "Cancel reasons",
                  "Use step cancel reasons",
                ],
                [
                  workflowLoaded.assessment?.ui?.buttons?.next?.text,
                  "Buttons",
                  "Custom Next button caption",
                ],
                [
                  workflowLoaded.assessment?.ui?.buttons?.next?.teaching,
                  "Buttons",
                  "Custom hint text",
                ],
                [
                  workflowLoaded.assessment?.ui?.buttons?.cancel?.text,
                  "Buttons",
                  "Custom Cancel button caption",
                ],
                [
                  workflowLoaded.assessment?.ui?.buttons?.back?.text,
                  "Buttons",
                  "Custom Back button caption",
                ],
                [
                  workflowLoaded.assessment?.ui?.stageTitle,
                  "Step UI",
                  "Use custom step title",
                ],
                [
                  workflowLoaded.assessment?.ui?.header,
                  "Step UI",
                  "Use custom step header",
                ],
                [
                  workflowLoaded.assessment?.ui?.information,
                  "Step UI",
                  "Use custom step information",
                ],
                [
                  workflowLoaded.assessment?.requireConfirmation,
                  "Confirmation",
                  "Use step confirmation",
                ],
                [
                  workflowLoaded.assessment?.ui?.confirmation?.header,
                  "Confirmation",
                  "Use custom confirmation header",
                ],
                [
                  workflowLoaded.assessment?.ui?.confirmation?.information,
                  "Confirmation",
                  "Use custom confirmation information",
                ],
                [
                  workflowLoaded.assessment?.ui?.confirmation?.buttons?.confirm
                    ?.text,
                  "Confirmation",
                  "Custom Confirm button caption",
                ],
                [
                  workflowLoaded.assessment?.ui?.confirmation?.buttons?.cancel
                    ?.text,
                  "Confirmation",
                  "Custom Cancel button caption",
                ],
                [
                  workflowLoaded.commonOffer?.ui?.tradeInDevice,
                  "Common offer settings",
                  "Custom trade-in device title",
                ],
                [
                  workflowLoaded.commonOffer?.withoutPrices,
                  "Common offer settings",
                  "Do not stop workflow if no offer found",
                ],
                [
                  workflowLoaded.commonOffer?.allowDiscount,
                  "Campaigns/UI settings",
                  "Allow campaigns",
                ],
                [
                  workflowLoaded.commonOffer?.discount?.ui?.tradeUpDevice,
                  "Campaigns/UI settings",
                  "Custom trade-up device title",
                ],
                [
                  workflowLoaded.commonOffer?.discount?.ui?.button,
                  "Campaigns/UI settings",
                  "Custom Campaign button caption",
                ],
                [
                  workflowLoaded.commonOffer?.discount?.ui?.header,
                  "Campaigns/UI settings",
                  "Custom Campaign button hint header text",
                ],
                [
                  workflowLoaded.commonOffer?.discount?.ui?.information,
                  "Campaigns/UI settings",
                  "Custom Campaign panel information",
                ],
                [
                  workflowLoaded.commonOffer?.discount?.ui?.placeholder,
                  "Campaigns/UI settings",
                  "Custom Campaign panel search input placeholder",
                ],

                [
                  workflowLoaded.contract?.useSubscription,
                  "Additional",
                  "Enable marketing consent request",
                ],
                [
                  workflowLoaded.contract?.useDaData,
                  "Additional",
                  "Use DaData",
                ],
                [
                  workflowLoaded.identification.useGroupAsSku,
                  "Additional",
                  "Use device group as SKU code",
                ],
                [
                  workflowLoaded.identification.moveNextStageOnComplete,
                  "Additional",
                  "Skip device identification summary page",
                ],

                [
                  workflowLoaded.identification.deviceGroups,
                  "Device groups",
                  "Allowed device groups (from the workflow catalog)",
                ],

                [
                  workflowLoaded.postOffer?.autoSelectBestOffer,
                  "Additional",
                  "Select best offer autommatically",
                ],

                [
                  workflowLoaded.postOffer?.ui?.header,
                  "Step UI",
                  "Custom post-offer stage subtitle",
                ],
                [
                  workflowLoaded.postOffer?.ui?.information,
                  "Step UI",
                  "Custom information for the post-offer stage",
                ],

                [
                  workflowLoaded.preOffer?.useAnalogs,
                  "Additional",
                  "Show device analogs",
                ],
                [
                  workflowLoaded.preOffer?.useAnalogsChoice,
                  "Additional",
                  "Allow analog selection",
                ],
                [
                  workflowLoaded.preOffer?.showPricesBeforeAssessment,
                  "Additional",
                  "Show offer prices",
                ],
                [
                  workflowLoaded.preOffer?.showBestPriceOnly,
                  "Additional",
                  "Show the best price only",
                ],
                [
                  workflowLoaded.assessment?.modules.some(
                    (m) => m.config.skipSummary
                  ),
                  "Common settings",
                  "Skip summary",
                ],
                [workflowLoaded.preOffer?.ui?.stageTitle, "hasOffer", "Custom message text"],
                [workflowLoaded.preOffer?.ui?.information, "hasOffer", "Custom information text"],
              ];

              workflowLoaded.assessment?.modules.map(
                (m) => m.config.skipSummary
              );

              return stageSectionCases.reduce(
                (acc: boolean, val: [unknown, string, string]) =>
                  acc && !!val[0] && checkStageSection(s.type, val[1], val[2]),
                false
              );
            }) || [];

          if (processStageSectionOptionAccessFails.some(Boolean)) {
            pushMessage(
              `${strings.CONSTRUCTOR.WARNINGS.CONFIGURATION_INCORRECT} ${strings.CONSTRUCTOR.WARNINGS.INCORRECT_SECTION_SETTINGS}`
            );
            setConfirmDisabled(true);
          }
          if (!isCheckedRequiredDataExists(workflowLoaded) || isSpecificDataMisplaced(workflowLoaded)) {
            pushMessage(
              `${strings.CONSTRUCTOR.WARNINGS.CONFIGURATION_INCORRECT} ${strings.CONSTRUCTOR.WARNINGS.INCORRECT_SETTINGS}`
            );
            setConfirmDisabled(true);
          }
          if (workflowSettingsFails) {
            pushMessage(
              `${strings.CONSTRUCTOR.WARNINGS.CONFIGURATION_INCORRECT} ${strings.CONSTRUCTOR.WARNINGS.INCORRECT_SETTINGS}`
            );
            setConfirmDisabled(true);
          }
          if (identificationModuleFails.some(Boolean)) {
            pushMessage(
              `${strings.CONSTRUCTOR.WARNINGS.CONFIGURATION_INCORRECT} ${strings.CONSTRUCTOR.WARNINGS.UNSUPPORTED_IDENTIFICATION}`
            );
            setConfirmDisabled(true);
          }
          if (assessmentModuleFails && assessmentModuleFails.some(Boolean)) {
            pushMessage(
              `${strings.CONSTRUCTOR.WARNINGS.CONFIGURATION_INCORRECT} ${strings.CONSTRUCTOR.WARNINGS.UNSUPPORTED_MODULE}`
            );
            setConfirmDisabled(true);
          }
          if (
            processStageAccessFails &&
            processStageAccessFails.some(Boolean)
          ) {
            pushMessage(
              `${strings.CONSTRUCTOR.WARNINGS.CONFIGURATION_INCORRECT} ${strings.CONSTRUCTOR.WARNINGS.UNSUPPORTED_PROCESS}`
            );
            setConfirmDisabled(true);
          }
        }
        const scannerItem = workflowLoaded.identification.items.filter(
          (item) => item.method === IdentificationMethods.Scanner
        );
        if (
          workflowLoaded.channel === Experience.Mobile &&
          scannerItem.some(
            (i) =>
              !(i as any)["type"].includes(ScannerIdentificationTypes.Label)
          )
        ) {
          let inspecitonUploadMethods: any[] = [];
          let inspectionDefaultMethod: unknown;
          scannerItem.forEach((i) => {
            const methods = (i as any)["uploadMethods"];
            if (methods) {
              inspecitonUploadMethods = [...inspecitonUploadMethods, methods];
            }
            const defaultInspectionMethod = (i as any)["defaultUploadMethod"];
            if (defaultInspectionMethod) {
              inspectionDefaultMethod = defaultInspectionMethod;
            }
          });

          if (
            inspecitonUploadMethods[0].includes(ScannerUploadMethods.Camera) ||
            inspectionDefaultMethod === ScannerUploadMethods.Camera
          ) {
            pushMessage(
              strings.CONSTRUCTOR.WARNINGS.INCOMPATIBLE_AI_SCANNER_METHOD
            );
            setConfirmDisabled(true);
          }
          const aiInspection = workflowLoaded.assessment?.modules.filter(
            (item) => item.type === Inspections.AIGrading
          );
          let inspectionItems: any[] = [];
          let gradingDefaultMethod: unknown;
          aiInspection?.forEach((i) => {
            const gradingUploadMethods = (i.config as any)["config"][
              "uploadMethods"
            ];
            if (gradingUploadMethods) {
              inspectionItems = [...inspectionItems, gradingUploadMethods];
            }
            const defaultGradingMethod = (i.config as any)["config"][
              "defaultUploadMethod"
            ];
            if (defaultGradingMethod) {
              gradingDefaultMethod = defaultGradingMethod;
            }
          });

          if (
            (inspectionItems[0] &&
              inspectionItems[0].includes(ScannerUploadMethods.Camera)) ||
            gradingDefaultMethod === ScannerUploadMethods.Camera
          ) {
            pushMessage(
              strings.CONSTRUCTOR.WARNINGS.INCOMPATIBLE_AI_GRADING_METHOD
            );
            setConfirmDisabled(true);
          }
        }

        let promotionsIds: string[] = [];
        const promotionsResult =
          await portalApi.organization.promotions.getPromotions();
        if (promotionsResult.successed && promotionsResult.data) {
          promotionsIds = promotionsResult.data
            .filter((i) => !i.removed)
            .map((i) => i.id);
          console.log("promotionsResult.dataIds:", promotionsIds);
        }

        if (workflowLoaded.stages) {
          console.log("workflow.stages:", workflowLoaded.stages);
          if (workflowLoaded.commonOffer?.providers) {
            const workflowProvidersCatalogIds =
              workflowLoaded.commonOffer?.providers
                .filter((i) => i.type === OfferProviderTypes.Catalog)
                .map((i) => i.catalogId);
            if (workflowProvidersCatalogIds.length > 0) {
              hasCatalogWarning = !!workflowProvidersCatalogIds.find(
                (cid) => cid && !catalogsIds.includes(cid)
              );
            }
          }
          if (workflowLoaded.commonOffer?.promoProviders) {
            const workflowPromoProvidersPromotionsIds =
              workflowLoaded.commonOffer?.promoProviders.map(
                (i) => i.promotionId
              );
            if (workflowPromoProvidersPromotionsIds.length > 0) {
              hasPromotionWarning = !!workflowPromoProvidersPromotionsIds.find(
                (pid) => !promotionsIds.includes(pid)
              );
            }
          }
          if (hasCatalogWarning) {
            pushMessage(
              strings.CONSTRUCTOR.WARNINGS.CATALOG_WILL_BE_REMOVED,
              MessageBarType.warning
            );
          }
          if (hasPromotionWarning) {
            pushMessage(
              strings.CONSTRUCTOR.WARNINGS.CAMPAIGN_WILL_BE_REMOVED,
              MessageBarType.warning
            );
          }

          if (workflowLoaded.selfService && workflowLoaded.selfService.promotions){
            const result = await portalApi.organization.selfServicePromotion.getPromotions()
            if (result.data) {
              // find promotions that are related to current organization and enabled
              const promotionIdsEnabled = result.data
                  .filter(({removed, expirationDate}) =>
                      removed || expirationDate && compareCurrentUTCDateTime(expirationDate, true)
                  )
                  .map(({id}) => id)
              if (workflowLoaded.selfService.promotions.find((id) => !promotionIdsEnabled.includes(id))) {
                pushMessage(
                    strings.CONSTRUCTOR.WARNINGS.CATALOG_WILL_BE_REMOVED,
                    MessageBarType.warning
                );
              }
            }
          }
          if (workflowLoaded.selfService?.repairOffers && workflowLoaded.selfService?.repairOffers.providers){
            const result =
                await portalApi.organization.catalogs.getCatalogsByCatalogType(CatalogTypes.Repair);
            // find all relevant repair offers
            const repairOffersIds = result.data?.filter((i) => !i.removed).map(({id}) => id) || [];
            // if element has provider not from current organization
            if (workflowLoaded.selfService.repairOffers.providers.find(({catalogId}) => !repairOffersIds.includes(catalogId))) {
              pushMessage(
                  strings.CONSTRUCTOR.WARNINGS.REPAIR_OFFERS_WILL_BE_REMOVED,
                  MessageBarType.warning
              );
            }
          }

        } else {
          pushMessage(strings.CONSTRUCTOR.WARNINGS.JSON_NOT_CONTAIN_WORKFLOW);
        }

        console.log("messages:", messages);
      } catch (e) {
        // TODO: require proper error message
        console.log(e);
        pushMessage(strings.CONSTRUCTOR.WARNINGS.INCORRECT_FILE_TYPE);
        setConfirmDisabled(true);
      }
    } else {
      pushMessage(strings.CONSTRUCTOR.WARNINGS.INCORRECT_FILE_TYPE);
    }

    if (messages.length > 0) {
      setWorflowFileMessages(messages);
    } else {
      setWorflowFileMessages(undefined);
    }

    stopWorkflowCheck();
  };

  const onHideImportModal = () => {
    setWorflowFileMessages(undefined);
    hideImportModal();
  };

  const getQrCodeLink = (serviceId: number): string => {
    return endpoints.external.applicationWidget(applicationUrl, serviceId);
  };

  const getService = async (id: number) => {
    setServiceState({ ...serviceState, fetched: false });
    const result = await portalApi.organization.services.getService(id);
    console.log(result);
    if (result.successed && result.data) {
      setServiceState({
        fetched: true,
        data: result.data,
        qrCodeLink: getQrCodeLink(result.data.id as number),
      });
    }
    if (result.errors && result.errors.length > 0) {
      hideConfirmation();
      setError(result.errors[0].description);
    }
  };

  const updateService = async (
    item: IService
  ): Promise<IRequestResult<IService>> => {
    setFormError(undefined);
    startFetch();
    console.log(item);
    const result = await portalApi.organization.services.updateService(item);
    console.log(result);
    if (result.successed && result.data) {
      setServiceState({
        fetched: true,
        data: result.data,
        qrCodeLink: getQrCodeLink(result.data.id as number),
      });
      hidePanel();
      stopFetch();
    }
    if (result.errors && result.errors.length > 0) {
      setError(result.errors[0].description);
    }
    return result;
  };

  const updateServiceInformation = async (information: string) => {
    if (serviceState.data?.id) {
      startFetchInfo();
      setInfoWasSaved(false);
      const result =
        await portalApi.organization.services.updateServiceInformation(
          serviceState.data?.id,
          information
        );
      if (result.successed) {
        setServiceState({
          ...serviceState,
          data: { ...serviceState.data, information: information },
        });
        setInfoWasSaved(true);
      }
      if (result.errors && result.errors.length > 0) {
        setError(result.errors[0].description);
      }
      stopFetchInfo();
    }
  };

  const deleteService = async (id: number) => {
    startFetch();
    const result = await portalApi.organization.services.deleteService(id);
    if (result.successed) {
      history.push("/services/configuration");
    }
    if (result.errors && result.errors.length > 0) {
      hideConfirmation();
      setError(result.errors[0].description);
      return;
    }
    if (!result.successed) {
      setError(
        "There is a problem! Service wasn't deleted! Server response false without an error"
      );
    }
    stopFetch();
    hideConfirmation();
  };

  const onFormSubmit = (data: IService) => {
    serviceState.data?.id && updateService(data);
  };

  const onConfigurationImportSuccessed = () => {
    if (serviceState.data?.id) {
      getService(serviceState.data?.id);
    }
  };

  return (
    <Stack verticalFill style={{ position: "relative" }}>
      {!error &&
        ((serviceState.data && (
          <ScrollablePane>
            <Stack verticalFill>
              <Stack.Item>
                <ServiceConfigurationHeader
                  generateIcon={workflowIconGeneration}
                  onEdit={showPanel}
                  item={serviceState.data}
                  onShareWorkflow={showShareWorkflow}
                />
              </Stack.Item>
              <Stack.Item verticalFill>
                <ServiceConfigurationDetails
                  setInformation={updateServiceInformation}
                  retryInfo={() => getService(parseInt(serviceId))}
                  data={serviceState.data}
                  onDelete={showConfirmation}
                  onImportWorkflow={showImportModal}
                  updateService={updateService}
                  infoFetching={infoFetching}
                  isFetching={fetching}
                  infoWasSaved={infoWasSaved}
                  generateIcon={workflowIconGeneration}
                  resetInfoWasSaved={() => setInfoWasSaved(false)}
                />
              </Stack.Item>
            </Stack>
          </ScrollablePane>
        )) || (
          <Stack
            verticalAlign="center"
            verticalFill
            horizontalAlign="center"
            tokens={{ childrenGap: 12 }}
          >
            <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
          </Stack>
        ))}
      {error && (
        <Stack verticalFill>
          {error.length > 0 && (
            <MessageBar messageBarType={MessageBarType.error}>
              {error}
            </MessageBar>
          )}
          <Stack.Item verticalFill>
            <SomethingWentWrong
              action={() => getService(parseInt(serviceId))}
            />
          </Stack.Item>
        </Stack>
      )}
      <CustomPanel
        isOpen={isPanelOpen}
        onCancel={hidePanel}
        title={strings.ORGANIZATION.SERVICES.SERVICE.EDIT_SERVICE}
      >
        {formError && (
          <MessageBar messageBarType={MessageBarType.error}>
            {formError}
          </MessageBar>
        )}
        {(!formError && !fetching && (
          <ServiceForm
            onSubmit={(data: IService) => onFormSubmit(data)}
            onCancel={hidePanel}
            data={serviceState.data as IService}
          />
        )) || (
          <Stack verticalAlign="center" verticalFill horizontalAlign="center">
            <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
          </Stack>
        )}
      </CustomPanel>

      <Modal
        isOpen={isShareWorkflowPanelOpen}
        onDismiss={hideShareWorkflow}
        containerClassName={shareQrCodeModalStyles.container}
      >
        <div className={shareQrCodeModalStyles.header}>
          <IconButton
            styles={iconButtonStyles}
            iconProps={{ iconName: "Cancel" }}
            ariaLabel="Close"
            onClick={hideShareWorkflow}
          />
        </div>
        <div className={shareQrCodeModalStyles.body}>
          <ShareWorkflowDialog qrCodeLink={serviceState.qrCodeLink} />
        </div>
      </Modal>

      {serviceState.data?.id && (
        <Modal
          isOpen={confirmation}
          onDismiss={hideConfirmation}
          containerClassName={contentStyles.container}
        >
          <div className={contentStyles.header}>
            <span style={{ paddingRight: 32 }}>
              {strings.ORGANIZATION.SERVICES.SERVICE.REMOVE_CONFIRMATION_TITLE}
            </span>
            <IconButton
              styles={iconButtonStyles}
              iconProps={{ iconName: "Cancel" }}
              ariaLabel="Close"
              onClick={hideConfirmation}
            />
          </div>
          <div className={contentStyles.body}>
            <ConfirmationForm
              onConfirm={() =>
                serviceState.data?.id && deleteService(serviceState.data?.id)
              }
              onCancel={hideConfirmation}
              requiredMessageString={
                strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.NAME_REQUIRED
              }
              confirmationCheckString={serviceState.data?.name}
              placeholder={
                strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.NAME_PLACEHOLDER
              }
              confirmText={strings.BUTTONS.TEXT.DELETE}
            >
              <Stack tokens={{ childrenGap: 12 }}>
                <Text>
                  {
                    strings.ORGANIZATION.SERVICES.SERVICE
                      .REMOVE_CONFIRMATION_TEXT1
                  }
                </Text>
                <Text>
                  {
                    strings.ORGANIZATION.SERVICES.SERVICE
                      .REMOVE_CONFIRMATION_TEXT2
                  }
                </Text>
                <Text>
                  {
                    strings.ORGANIZATION.SERVICES.SERVICE
                      .REMOVE_CONFIRMATION_REQUIREMENTS
                  }
                  <b>"{serviceState.data?.name}"</b>
                </Text>
              </Stack>
            </ConfirmationForm>
          </div>
        </Modal>
      )}
      {isImportModalShowed && (
        <ImportFromFile
          type="icon"
          src="FileCode"
          show={isImportModalShowed}
          onHide={onHideImportModal}
          acceptTypeMask={".json"}
          allowedFileTypes={["application/json"]}
          lable={strings.ORGANIZATION.SERVICES.SERVICE.IMPORT_LABEL}
          description={strings.ORGANIZATION.SERVICES.SERVICE.IMPORT_DESCRIPTION}
          iconColor={SharedColors.orangeYellow10}
          title={strings.ORGANIZATION.SERVICES.SERVICE.IMPORT_TITLE}
          sendParams={{ serviceId: serviceId }}
          onSuccessed={onConfigurationImportSuccessed}
          sendMethod={
            portalApi.organization.services.updateServiceConfiguration
          }
          onFileChange={checkWorkflowForUpload}
          messages={workflowFileMessages}
          resetMessages={() => setWorflowFileMessages(undefined)}
          isFetching={workflowIsChecking}
          noReset
          disableConfirm={confirmIsDisabled}
        />
      )}
    </Stack>
  );
};

/** Validates assesment modules */
const isAssesmentModulesValid = (currentService: IService | undefined, workflowLoaded: IWorkflow) : boolean => {
  if (!currentService) return false;

  if (!workflowLoaded.assessment || !workflowLoaded.assessment.modules)
    return true;

  const solution = currentService.deviceCheckSolution;

  const isValid = workflowLoaded.assessment.modules.every(m => {
    // validate DGS module
    if (m.type === Inspections.Diagnostics) {
      const config = m.config as IDiagnosticsConfig;

      // validate dgs mode matches to current solution

      if (solution === DeviceCheckSolutions.PiceaOne && config.mode !== DiagnosticsModes.Piceasoft) return false;
      else if (solution === DeviceCheckSolutions.PiceaMobile && config.mode !== DiagnosticsModes.PiceaMobile) return false;
      else if (solution === DeviceCheckSolutions.WebBased && config.mode !== DiagnosticsModes.WebBased) return false;

    }
    return true;
  });

  return isValid;
}

/** Validates commonOffer */
const isCommonOfferValid = (workflowLoaded: IWorkflow) : boolean => {
  if (!workflowLoaded.commonOffer)
    return true;

  const providers = workflowLoaded.commonOffer.providers;

  if (!providers || providers.length === 0)
    return true;

  // item code is unique, verify no duplicates
  const codes = providers.map(item => item.code);
  if (hasDuplicates(codes)) {
    return false;
  }

  // determine required string fields by OfferProviderTypes
  const requiredStringFieldsByType = {
    [OfferProviderTypes.Offer]: ['name', 'code', 'endpoint', 'currency'],
    [OfferProviderTypes.Catalog]: ['name', 'code', 'catalogId'],
  }

  for (let i = 0; i < providers.length; i++) {
    const item = providers[i];
    //console.log("validate index: " + i);

    if (item.type !== OfferProviderTypes.Offer && item.type !== OfferProviderTypes.Catalog) {
      return false;
    }

    // verify all required string fields by type (just verifies that string value exists)
    for (const fieldName of requiredStringFieldsByType[item.type]) {
      if (!verifyRequiredStringField(item, fieldName)) {
        //console.log("Required field invalid: " + fieldName);
        return false;
      }
    }

    // validate commissionType and commissionValue
    if (typeof item.commissionType === 'number') {
      if (item.commissionType === CommissionType.Percentage) {
        if (typeof item.commissionValue !== 'number' || (item.commissionValue < 0 || item.commissionValue > 100)) {
          return false;
        }
      }
      else if (item.commissionType === CommissionType.Value) {
        if (typeof item.commissionValue !== 'number' || item.commissionValue < 0) {
          return false;
        }
      }
      else {
        return false;
      }
    }
    else {
      // ok, no commissions
      item.commissionType = undefined;
      item.commissionValue = undefined;
    }

    if (typeof item.index === 'number') {
      // index defined, verify it
      if (item.index !== i) {
        return false;
      }
    }
    else {
      // index not defined, generate
      item.index = i;
    }

  }

  // all seems to be ok
  return true;
}

/** Returns true if duplcate values found from array */
function hasDuplicates<T>(array: T[]): boolean {
  const uniqueValues: Set<T> = new Set();
  for (const value of array) {
    if (uniqueValues.has(value)) {
        return true;
    }
    uniqueValues.add(value);
  }
  return false;
}

/**
 * Verifies that obj contains fieldName and it is valid string.
 * @param obj any object
 * @param fieldName field to test
 * @returns true if valid
 */
function verifyRequiredStringField(obj: any, fieldName: string): boolean {
  if (fieldName in obj) {
      const fieldValue = obj[fieldName];
      if (fieldValue) {
          if (typeof fieldValue !== 'string' || fieldValue.trim().length === 0) {
              return false;
          }
          return true;
      }
  }
  return false;
}

const theme = getTheme();

const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    width: 480,
    borderRadius: 6,
  },
  header: [
    theme.fonts.large,
    {
      flex: "1 1 auto",
      borderTop: `none`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],
  body: {
    position: "relative",
    flex: "4 4 auto",
    padding: "0px 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});
const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const shareQrCodeModalStyles = mergeStyleSets({
  container: {
    borderRadius: 6,
  },
  header: [
    theme.fonts.large,
    {
      flex: "1 1 auto",
      borderTop: `none`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "0px 0px 0px 12px",
    },
  ],
  body: {
    position: "relative",
    flex: "4 4 auto",
    padding: "0px 24px 24px 24px",
    overflowY: "hidden",
    height: 270,
    width: 600,
  },
});
